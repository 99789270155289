import { v4 } from "uuid";
import { SectionsKey } from "~/constants/enum";
import { getTheme } from "./theme";

export const getPageStruct = (themeName = getTheme(), fileName = "home") => {
  try {
    return require(`~/theme/${themeName}/structure/${fileName}.struct.ts`)
      .default;
  } catch (error) {
    return require(`~/theme/base/structure/${fileName}.struct.ts`).default;
  }
};

export const renderSection = async (
  item: any,
  theme = "default",
  data: any = {}
) => {
  let Component: any;
  const componentProps = {
    ...(!!item.dataKey ? data[item.dataKey] || {} : {}),
  };
  switch (item.id) {
    case SectionsKey.Hero:
      try {
        Component = await import(`~/theme/${theme}/sections/Hero`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Hero`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.Brand:
      try {
        Component = await import(`~/theme/${theme}/sections/Brand`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Brand`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.RecentlyView:
      try {
        Component = await import(`~/theme/${theme}/sections/RecentlyView`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/RecentlyView`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.TopCategories:
      try {
        Component = await import(`~/theme/${theme}/sections/TopCategory`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/TopCategory`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.BestSeller:
      try {
        Component = await import(`~/theme/${theme}/sections/BestSeller`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/BestSeller`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.MostPopular:
      try {
        Component = await import(`~/theme/${theme}/sections/MostPopular`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/MostPopular`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.ProductMatching:
      try {
        Component = await import(`~/theme/${theme}/sections/ProductMatching`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductMatching`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.FeatureProduct:
      try {
        Component = await import(`~/theme/${theme}/sections/FeaturedProducts`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/FeaturedProducts`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "new-collections":
      try {
        Component = await import(`~/theme/${theme}/sections/NewCollection`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/NewCollection`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.Trending:
      try {
        Component = await import(`~/theme/${theme}/sections/Trending`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Trending`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.Testimonial:
      try {
        Component = await import(`~/theme/${theme}/sections/Testimonial`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Testimonial`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.Recommended:
      try {
        Component = await import(`~/theme/${theme}/sections/Recommended`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Recommended`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.ProductDetail:
      try {
        Component = await import(`~/theme/${theme}/sections/ProductDetail`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductDetail`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "product-detail-tabs":
      try {
        Component = await import(`~/theme/${theme}/sections/ProductDetailTabs`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductDetailTabs`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "product-section":
      try {
        Component = await import(`~/theme/${theme}/sections/ProductsSlider`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductsSlider`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.ProductRelated:
      try {
        Component = await import(`~/theme/${theme}/sections/ProductRelated`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductRelated`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.ProductRelatedCart:
      try {
        Component = await import(
          `~/theme/${theme}/sections/ProductRelatedCart`
        );

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductRelatedCart`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "product-offers-section":
      try {
        Component = await import(`~/theme/${theme}/sections/Offers`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Offers`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.ProductDetailBanner:
      try {
        Component = await import(
          `~/theme/${theme}/sections/ProductDetailBanner`
        );

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductDetailBanner`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "product-group":
      try {
        Component = await import(`~/theme/${theme}/sections/ProductGroup`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductGroup`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.Newsletter:
      try {
        Component = await import(`~/theme/${theme}/sections/NewsLetter`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/NewsLetter`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "wishlist":
      try {
        Component = await import(`~/theme/${theme}/sections/Wishlist`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Wishlist`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "sign-in":
      try {
        Component = await import(`~/theme/${theme}/sections/SignIn`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/SignIn`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "sign-up":
      try {
        Component = await import(`~/theme/${theme}/sections/SignUp`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/SignUp`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "forgot-password":
      try {
        Component = await import(`~/theme/${theme}/sections/ForgotPassword`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ForgotPassword`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "reset-password":
      try {
        Component = await import(`~/theme/${theme}/sections/ResetPassword`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ResetPassword`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "blog-slider":
      try {
        Component = await import(`~/theme/${theme}/sections/BlogSlider`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/BlogSlider`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "blog-list":
      try {
        Component = await import(`~/theme/${theme}/sections/BlogList`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/BlogList`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "cart":
      try {
        Component = await import(`~/theme/${theme}/sections/Cart`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Cart`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.Checkout:
      try {
        Component = await import(`~/theme/${theme}/sections/Checkout`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Checkout`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "thank-you":
      try {
        Component = await import(`~/theme/${theme}/sections/ThankYou`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ThankYou`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "offer-products":
      try {
        Component = await import(`~/theme/${theme}/sections/OfferProducts`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/OfferProducts`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "offer-code":
      try {
        Component = await import(`~/theme/${theme}/sections/OfferCode`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/OfferCode`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "profile-information":
      try {
        Component = await import(
          `~/theme/${theme}/sections/ProfileInformation`
        );

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProfileInformation`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "order-history":
      try {
        Component = await import(`~/theme/${theme}/sections/OrderHistory`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/OrderHistory`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "delivery-addresses":
      try {
        Component = await import(`~/theme/${theme}/sections/DeliveryAddresses`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/DeliveryAddresses`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "categories":
      try {
        Component = await import(`~/theme/${theme}/sections/Categories`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Categories`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "product-list":
      try {
        Component = await import(`~/theme/${theme}/sections/ProductList`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductList`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "order-detail":
      try {
        Component = await import(`~/theme/${theme}/sections/OrderDetail`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/OrderDetail`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "reviews":
      try {
        Component = await import(`~/theme/${theme}/sections/Reviews`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Reviews`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "collection-about":
      try {
        Component = await import(`~/theme/${theme}/sections/CollectionAbout`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/CollectionAbout`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "collections":
      try {
        Component = await import(`~/theme/${theme}/sections/Collections`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/Collections`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "collection-recommend":
      try {
        Component = await import(
          `~/theme/${theme}/sections/CollectionRecommend`
        );

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/CollectionRecommend`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }

    case "blog-detail":
      try {
        Component = await import(`~/theme/${theme}/sections/BlogDetail`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/BlogDetail`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "related-blogs":
      try {
        Component = await import(`~/theme/${theme}/sections/RelatedBlogs`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/RelatedBlogs`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case "not-found":
      try {
        Component = await import(`~/theme/${theme}/sections/PageNotFound`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/PageNotFound`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.ProductDetailTwo:
      try {
        Component = await import(`~/theme/${theme}/sections/ProductDetailTwo`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductDetailTwo`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.ProductDetailMoreInfo:
      try {
        Component = await import(
          `~/theme/${theme}/sections/ProductDetailMoreInfo`
        );

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductDetailMoreInfo`);
      }
    case "tab-reviews":
      try {
        Component = await import(`~/theme/${theme}/sections/TabReviews`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/TabReviews`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
    case SectionsKey.ProductTags:
      try {
        Component = await import(`~/theme/${theme}/sections/ProductTags`);

        return <Component.default key={item.id + v4()} {...componentProps} />;
      } catch (error) {
        Component = await import(`~/sections/ProductTags`);
        return <Component.default key={item.id + v4()} {...componentProps} />;
      }
  }
};

export const renderPage = async <T extends unknown>(
  pageSections: any,
  theme = "default",
  data?: {
    [key: string]: T;
  }
) => {
  return Promise.all(
    pageSections.map((item: any) => renderSection(item, theme, data))
  );
};
