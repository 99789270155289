import { SectionsKey } from "~/constants/enum";

const PageStruct = {
  id: "product-detail",
  useLayout: true,
  children: [
    { id: SectionsKey.Breadcrumb, order: 1 },
    { id: SectionsKey.ProductDetailTwo, order: 2 },
    { id: SectionsKey.ProductDetailBanner, order: 4 },
  ],
};

export default PageStruct;
